@include generate-margin-padding-in-rem(0, 25);
@include generate-margin-padding-in-rem(0, -25);
@include generate-margin-padding-in-px(1, 16);

.px-80 {
  padding-right: 80px;
  padding-left: 80px;
  @media screen and (max-width: 460px) {
    padding-right: 16px;
    padding-left: 16px;
  }
}

.px-sm-30 {
  padding: 0px 30px;
  @include media(767px) {
    padding: 0px 16px;
  }
}
.p-sm-24 {
  padding: 24px !important;
  @include media(767px) {
    padding: 16px !important;
  }
}
.px-sm-24 {
  padding: 0px 24px !important;
  @include media(767px) {
    padding: 0px 16px !important;
  }
}
.pt-sm-24 {
  padding-top: 24px !important;
  @include media(767px) {
    padding-top: 16px !important;
  }
}
.pl-sm-24 {
  padding-left: 24px !important;
  @include media(767px) {
    padding: 12px !important;
  }
}

.m-auto {
  margin: auto !important;
}
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.m-sm-30 {
  margin: 30px;
  @include media(767px) {
    margin: 16px;
  }
}
.mb-sm-30 {
  margin-bottom: 30px;
  @include media(767px) {
    margin-bottom: 16px;
  }
}

@include generate-height-width(0, 400);

.w-full {
  width: 100%;
}
.w-full-screen {
  width: 100vw;
}
.min-w-750 {
  min-width: 750px;
}
.max-w-770 {
  max-width: 770px;
}

.h-full {
  height: 100% !important;
}
.h-full-screen {
  height: 100vh;
}
.h-150px {
  height: 150px !important;
}

.size-36 {
  height: 36px !important;
  width: 36px !important;
}
.size-24 {
  height: 24px !important;
  width: 24px !important;
}
