.footer {
  min-height: $topbar-height;

  @include media(499px) {
    display: table;
    width: 100%;
    min-height: auto;
    padding: 1rem 0;
    .container {
      flex-direction: column !important;
      a {
        margin: 0 0 16px !important;
      }
    }
  }
}
