.crud-table {
    thead {
        tr {
            th:first-child {
                padding-left: 16px !important;
            }
            white-space: 'nowrap';
        }
    }
    tbody {
        tr {
            white-space: 'nowrap';
            transition: background 300ms ease;
            &:hover {
                background: $light-gray;
            }
            td {
                border-bottom: none;
                // text-transform: capitalize;
            }
            td:first-child {
                padding-left: 16px !important;
            }
        }
    }
}
